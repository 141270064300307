<template>
  <div class="bottom-bar elevation-6 pa-3">
    <img-upload v-if="showImgUpload" v-model="img"/>
    <files-field v-if="showFilesField" v-model="files"/>

    <v-textarea
      v-model="text"
      :readonly="submitting"
      outlined
      rounded
      rows="1"
      auto-grow
      dense
      hide-details
      loader-height="0"
      class="message-editor"
      @keydown.ctrl.enter="submitNow"
    >
      <template slot="prepend-inner">
        <v-btn icon @click="showEmojiPicker = !showEmojiPicker">
          <v-icon>mdi-emoticon-outline</v-icon>
        </v-btn>
      </template>

      <template slot="append-outer">
        <v-btn v-if="media" icon small color="grey" @click="showFilesField = !showFilesField">
          <v-icon>mdi-attachment</v-icon>
        </v-btn>
        <v-btn v-if="media" icon small color="grey" @click="showImgUpload = !showImgUpload">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn fab small color="primary" :disabled="!text && !img && !(files && files.length > 0)" :loading="submitting" @click="submitNow">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </template>
    </v-textarea>

    <v-dialog
      v-model="showEmojiPicker"
      transition="dialog-bottom-transition"
    >
      <v-emoji-picker v-if="showEmojiPicker" @select="selectEmoji"/>
    </v-dialog>
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import FilesField from '@/components/base/FilesField'
import ImgUpload from '@/components/base/ImgUpload'

export default {
  name: 'MessageEditor',
  components: { VEmojiPicker, FilesField, ImgUpload },
  props: {
    submit: Function,
    media: Boolean
  },
  data () {
    return {
      text: '',
      img: null,
      files: null,
      submitting: false,
      showEmojiPicker: false,
      showImgUpload: false,
      showFilesField: false
    }
  },
  methods: {
    selectEmoji (emoji) {
      this.text += emoji.data
      this.showEmojiPicker = false
    },
    async submitNow () {
      this.submitting = true
      this.showEmojiPicker = false
      await this.submit({
        text: this.text,
        img: this.img,
        files: this.files
      })
      this.text = ''
      this.img = null
      this.files = null
      this.showImgUpload = false
      this.submitting = false
    }
  }
}
</script>

<style scoped>
.message-editor >>> .v-text-field--rounded {
  border-radius: 20px;
}
.message-editor >>>  .v-input__slot {
  padding: 0 8px;
}
.message-editor >>> .v-input__prepend-inner,
.message-editor >>> .v-input__append-inner {
  margin-top: 3px !important;
}
.message-editor >>> .v-input__append-outer {
  margin: 0 !important;
}
.message-editor >>> .v-input__append-outer .v-btn--icon {
  margin: 6px 0 0 6px !important;
}
.message-editor >>> .v-input__append-outer .v-btn--fab {
  margin-left: 6px;
}
</style>
