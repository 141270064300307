<template>
  <div class="mb-5">
    <div class="caption caption-label mb-1">{{ $t('files') }}</div>

    <div class="mb-2">
      <div
        v-for="(file, i) in value" :key="i"
        class="d-flex mb-3"
      >
        <v-icon class="pr-1">mdi-attachment</v-icon>
        <span class="file-link pr-3" @click.stop="downloadFile(file)">{{ file.name }}</span>
        <v-icon small @click="delFile(i)">mdi-delete</v-icon>
      </div>
    </div>

    <v-file-input
      v-model="files"
      :label="$t('files_add')"
      :loading="uploading > 0"
      multiple
      filled
      outlined
      dense
      hide-details
      prepend-icon=""
      prepend-inner-icon="$file"
      @change="addFiles"
    />
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'

const FILE_SIZE_LIMIT = 10485760 // 10MB

export default {
  name: 'FilesField',
  mixins: [FileMixin],
  props: {
    value: Array // array of fields ([src, name, type])
  },
  data () {
    return {
      files: null,
      uploading: 0,
      progress: 0
    }
  },
  methods: {
    addFiles (files) {
      if (!files) return

      if (files.find(file => file.size > FILE_SIZE_LIMIT)) {
        this.$root.snack = {
          text: this.$t('file_too_big', { limit: 10 }),
          color: 'error'
        }
        return
      }
      
      files.forEach(file => {
        this.uploading++
        this.saveUploadedFile(file, p => { this.progress = p }) // TODO: combine progresses
          .then(fileName => {
            this.uploading--
            const newFile = {
              src: fileName,
              name: file.name,
              type: fileName.substr(fileName.lastIndexOf('.') + 1)
            }
            this.$emit('input', [
              ...(this.value || []),
              newFile
            ])
          })
      })
    },
    delFile (idx) {
      const files = [...this.value]
      files.splice(idx, 1)
      this.$emit('input', files)
    }
  },
  watch: {
    uploading (n, o) {
      if (n === 0 && o === 1) {
        this.files = null // clear
      }
    }
  }
}
</script>
