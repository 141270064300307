<template>
  <div>
    <div
      v-for="(file, i) in files" :key="i"
    >
      <v-icon class="pr-1">mdi-attachment</v-icon>
      <span class="file-link" @click.stop="downloadFile(file)">{{ file.name }}</span>
    </div>
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin'

export default {
  name: 'FilesList',
  mixins: [FileMixin],
  props: {
    files: Array
  }
}
</script>